// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
// import "./controllers"
import * as bootstrap from "bootstrap"


// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// initialize the page
// window.addEventListener('DOMContentLoaded', (event) => {
//   initPage();
// });
window.addEventListener('turbo:render', (event) => {
  initPage();
});

let initPage = () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

  const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
  const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new bootstrap.Dropdown(dropdownToggleEl))
}
// document.addEventListener("DOMContentLoaded", () => {
//
// });
